import type {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  UiNode,
} from "@ory/client";
import type { FormMethod } from "@remix-run/react";
import type { FC } from "react";
import { Fragment } from "react";
import { KsocForm } from "~/components/KsocForm";
import { FlowUiAlert } from "~/components/kratos/FlowUiAlert";
import { FlowUiNode } from "~/components/kratos/FlowUiNode";
import type { UiNodeInput } from "./types";

export const FlowUi: FC<
  (LoginFlow | RegistrationFlow | SettingsFlow | RecoveryFlow) & {
    replace?: boolean;
    groupOrder?: string[];
    filterNodes?: (node: UiNode) => boolean;
    shouldHideNodeFromDefaultGroup?: (
      nodeGroup: string,
      node: UiNode,
    ) => boolean;
  }
> = ({
  replace = true,
  groupOrder,
  filterNodes = () => true,
  shouldHideNodeFromDefaultGroup = () => false,
  ...flow
}) => {
  const groupedNodes = new Map<string, UiNode[]>();
  for (const node of flow.ui.nodes.filter(filterNodes))
    if (!groupedNodes.has(node.group)) groupedNodes.set(node.group, [node]);
    else groupedNodes.get(node.group)!.push(node);

  //  Pull out the default
  const defaultGroup = groupedNodes.get("default") ?? [];
  groupedNodes.delete("default");

  const nodeGroups = groupOrder
    ? Array.from(groupedNodes).sort(
        ([, [{ group: a }]], [, [{ group: b }]]) =>
          groupOrder.indexOf(a) - groupOrder.indexOf(b),
      )
    : Array.from(groupedNodes);

  return (
    <>
      {flow.ui.messages?.map((text) => <FlowUiAlert key={text.id} {...text} />)}
      {nodeGroups.map(([nodeGroup, nodes], index, groups) => {
        const [, prevGroup] = groups[index - 1] ?? [];

        return (
          <KsocForm
            method={(flow.ui.method as FormMethod) ?? "post"}
            replace={replace}
            key={index}
            className="tw-space-y-4"
          >
            {prevGroup &&
              !prevGroup.every(
                (node) => (node as UiNodeInput).attributes.type === "hidden",
              ) && <hr className="tw-bg-zinc-100" />}

            {defaultGroup.map((node, index) => {
              if (shouldHideNodeFromDefaultGroup(nodeGroup, node)) {
                return <Fragment key={index}></Fragment>;
              }
              return <FlowUiNode key={index} node={node} />;
            })}
            {nodes.map((node, index) => (
              <FlowUiNode key={index} node={node} />
            ))}
          </KsocForm>
        );
      })}
    </>
  );
};
