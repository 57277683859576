import type { FC } from "react";
import { FlowUiLabelledInput } from "./FlowUiLabelledInput";
import { FlowUiRawInput } from "./FlowUiRawInput";
import { FlowUiSubmit } from "./FlowUiSubmit";
import type { UiNodeInput } from "./types";

export const FlowUiInput: FC<UiNodeInput> = (node) => {
  switch (node.attributes.type) {
    case "hidden":
      return <FlowUiRawInput {...node} />;
    case "submit":
      return <FlowUiSubmit {...node} />;
    default:
      return <FlowUiLabelledInput {...node} />;
  }
};
