import type { UiText } from "@ory/client";
import { useTranslation } from "react-i18next";

export function useKratosTranslation(ns: keyof I18nResources = "auth") {
  const { t } = useTranslation(ns);
  return (text?: UiText | null) =>
    t(`kratos.${text?.id}`, {
      ...(text?.context ?? {}),
      defaultValue: text?.text || "",
    }) as string;
}
