import { Input } from "@ksoc-private/ui-core";
import { useNavigation } from "@remix-run/react";
import type { FC } from "react";
import type { UiNodeInput } from "./types";

export const FlowUiRawInput: FC<UiNodeInput> = ({
  attributes: {
    value,
    autocomplete,
    disabled,
    name,
    /* 
      We extract the two fields below to prevent these attributes been put
      on the inputs. Specific use case is the `Github` / `Google` sign in
      button. If `required` is added on the `username`/`password` field the
      user is unable to click the "sign in with github" / "google" button.
    */
    required: _required,
    onclick: _onclick,
    ...rest
  },
  meta: { label },
  messages,
}) => {
  const navigation = useNavigation();
  const isInvalid = messages.some(({ type }) => type === "error");
  return (
    <Input
      id={name}
      name={name}
      key={name}
      defaultValue={value}
      autoComplete={autocomplete}
      size="lg"
      placeholder={
        label &&
        `Enter your ${
          label.text.toUpperCase() === label.text
            ? label.text
            : label.text.toLowerCase()
        }`
      }
      disabled={disabled || navigation.state !== "idle"}
      aria-invalid={isInvalid}
      {...rest}
    />
  );
};
