import { Alert, AlertDescription } from "@ksoc-private/ui-core";
import type { UiText } from "@ory/client";
import type { FC } from "react";
import { useKratosTranslation } from "~/hooks/useKratosTranslation";

export const FlowUiAlert: FC<UiText> = (text) => {
  const t = useKratosTranslation();
  return (
    <Alert key={text.id} variant={text.type}>
      <AlertDescription>{t(text)}</AlertDescription>
    </Alert>
  );
};
