import { Alert, AlertDescription, AlertTitle } from "@ksoc-private/ui-core";
import type { FC } from "react";

// TODO: find a proper type for `error`
export const FlowError: FC<{ error?: any }> = ({ error }) => {
  if (!error) return null;
  const details = error.details as unknown as Record<string, string>;
  return (
    <Alert variant="error">
      <AlertTitle>{error.status}</AlertTitle>
      <AlertDescription>
        {error.reason ? <p>{error.reason}</p> : <p>{error.message}</p>}
        {process.env.NODE_ENV === "development" && details && (
          <>
            <h5>Developer Information</h5>
            {details.hint && <p>{details.hint}</p>}
            {details.reject_reason && <p>{details.reject_reason}</p>}
            {details.docs && (
              <p className="tw-mb-1">
                Read more in the <a href={details.docs}>Kratos documentation</a>
              </p>
            )}
          </>
        )}
      </AlertDescription>
    </Alert>
  );
};
