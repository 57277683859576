import { Button } from "@ksoc-private/ui-core";
import { useNavigation } from "@remix-run/react";
import type { FC } from "react";
import { useKratosTranslation } from "~/hooks/useKratosTranslation";
import type { UiNodeInput } from "./types";

export const FlowUiSubmit: FC<UiNodeInput> = ({
  group,
  attributes: { disabled, type = "submit", ...rest },
  meta,
}) => {
  const t = useKratosTranslation();
  const navigation = useNavigation();
  const className = ["tw-w-full", meta.label?.type, group]
    .filter(Boolean)
    .join(" ");

  return (
    <Button
      type={type as any}
      size="lg"
      variant={group === "oidc" ? "secondary" : "primary"}
      className={className}
      disabled={disabled || navigation.state !== "idle"}
      {...rest}
    >
      {t(meta.label)}
    </Button>
  );
};
