import type { UiNode } from "@ory/client";
import type { FC } from "react";
import { FlowUiAnchor } from "./FlowUiAnchor";
import { FlowUiImage } from "./FlowUiImage";
import { FlowUiInput } from "./FlowUiInput";
import { FlowUiScript } from "./FlowUiScript";
import { FlowUiText } from "./FlowUiText";
import type {
  UiNodeAnchor,
  UiNodeImage,
  UiNodeInput,
  UiNodeScript,
  UiNodeText,
} from "./types";

export const FlowUiNode: FC<{ node: UiNode }> = ({ node }) => {
  switch (node.type) {
    case "input":
      return <FlowUiInput {...(node as UiNodeInput)} />;
    case "a":
      return <FlowUiAnchor {...(node as UiNodeAnchor)} />;
    case "text":
      return <FlowUiText {...(node as UiNodeText)} />;
    case "script":
      return <FlowUiScript {...(node as UiNodeScript)} />;
    case "img":
      return <FlowUiImage {...(node as UiNodeImage)} />;
  }
};
