import { Link } from "@remix-run/react";
import type { FC } from "react";
import { useKratosTranslation } from "~/hooks/useKratosTranslation";
import type { UiNodeAnchor } from "./types";

export const FlowUiAnchor: FC<UiNodeAnchor> = ({
  attributes: { id, href, title },
}) => {
  const t = useKratosTranslation();
  return (
    <Link to={href} id={id}>
      {t(title)}
    </Link>
  );
};
