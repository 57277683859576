import {
  InputErrorMessage,
  InputHelperText,
  InputSuccessMessage,
} from "@ksoc-private/ui-core";
import type { UiText } from "@ory/client";
import type { FC } from "react";
import { useKratosTranslation } from "~/hooks/useKratosTranslation";

const messageComponents = {
  info: InputHelperText,
  error: InputErrorMessage,
  success: InputSuccessMessage,
};

export const FlowUiMessage: FC<UiText> = (text) => {
  const t = useKratosTranslation();
  const Message = messageComponents[text.type];
  return <Message key={text.id}>{t(text)}</Message>;
};
