import { Label, VStack } from "@ksoc-private/ui-core";
import type { FC } from "react";
import { useKratosTranslation } from "~/hooks/useKratosTranslation";
import { FlowUiMessage } from "./FlowUiMessage";
import { FlowUiRawInput } from "./FlowUiRawInput";
import type { UiNodeInput } from "./types";

export const FlowUiLabelledInput: FC<UiNodeInput> = (node) => {
  const t = useKratosTranslation();
  return (
    <VStack spacing={1}>
      <Label htmlFor={node.attributes.name}>{t(node.meta.label)}</Label>
      <FlowUiRawInput {...node} />
      {node.messages.map((message) => (
        <FlowUiMessage key={message.id} {...message} />
      ))}
    </VStack>
  );
};
